import { ChangeEvent, useState } from 'react';

import { useMutationWithdrawal } from '@/apis/friendly-pharmacist/user/useMutationWithdrawal';
import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY } from '@/constants/auth';

export default function useWithdrawal() {
  const [selectedReason, setSelectedReason] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [hasReadALL, setHasReadALL] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleChangeReason = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedReason(e.target.value);
  };

  const handleChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setTextareaValue(pre => (value.length <= 1000 || value.length < pre.length ? value : pre));
  };

  const hasReadAllToggle = (checked: boolean) => setHasReadALL(checked);

  const navigateToStep2 = () => setCurrentStep(2);

  const { mutate: mutateWithdrawal } = useMutationWithdrawal();

  const handleWithdrawalBtnClick = () => {
    mutateWithdrawal(textareaValue || selectedReason, {
      onSuccess: () => {
        setShowAlert(true);
        sessionStorage.removeItem(AUTH_TOKEN_KEY);
        sessionStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
      },
    });
  };

  return {
    showAlert,
    currentStep,
    selectedReason,
    textareaValue,
    hasReadALL,
    handleChangeReason,
    handleChangeTextarea,
    navigateToStep2,
    hasReadAllToggle,
    handleWithdrawalBtnClick,
  };
}
