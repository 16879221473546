import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertModal } from '@components/common/molecules/modal/alert-modal';
import { DeliveryAddressModal } from '@components/common/molecules/modal/delivery-address-modal';

import { useMutationOrder } from '@/apis/commerce/orders/useMutationOrder';
import { useFetchOrderForm } from '@/apis/commerce/preorders/useFetchOrderForm';
import { useFetchOrderOptions } from '@/apis/commerce/preorders/useFetchOrderOptions';
import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import { useFetchGetUserInfo } from '@/apis/friendly-pharmacist/user/useFetchGetUserInfo';
import paymentImg from '@/assets/ic/payment.svg';
import { PATH, PRIVACY_AGREEMENT_URL } from '@/constants/path';
import { WoPreorderSellerSection } from '@/generated/api/type/data-contracts';
import useLoginInfo from '@/hooks/use-login-info';
import useMixpanel from '@/hooks/use-mixpanel';
import { useSearchUrl } from '@/hooks/use-search-url';
import { numberFormat } from '@/utils/formats';
import { AUTH_TOKEN_KEY } from '@/constants/auth';
import { refreshAccessToken } from '@/apis/friendly-pharmacist/user/user';
import toast from 'react-hot-toast';
import { removeLocalStorage, removeSessionStorage } from '@/utils';

export const OrderTemplate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userToken } = useLoginInfo();
  const [paymentWidget, setPaymentWidget] = useState<any>(null);
  const [alertState, setAlertState] = useState<{
    isAlertVisible: boolean;
    header: string[];
    body: string[];
    buttons: { title: string; handleClick: () => void; style: string }[];
  }>({
    isAlertVisible: false,
    header: [''],
    body: [''],
    buttons: [{ title: '', handleClick: () => {}, style: 'primary' }],
  });
  const { data: userInfoData } = useFetchGetUserInfo({
    accessToken: userToken ?? '',
    options: { enabled: !!userToken },
  });
  const { data: userDetailData } = useFetchGetUserDetail({
    accessToken: userToken,
  });

  const { getStateBySearchParams } = useSearchUrl();
  const preorderId = getStateBySearchParams('id') ?? '';
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const { handleMixpanelEvent } = useMixpanel();

  useEffect(() => {
    handleMixpanelEvent('view_payment', { from: location?.state?.from ?? '' });
  }, [location?.state?.from]);

  const widgetClientKey = process.env.REACT_APP_TOSSPAYMENTS_KEY ?? '';
  const customerKey =
    userInfoData?.user_info?.id ?? userDetailData?.self_detail?.email ?? 'no_unique_key';

  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.tosspayments.com/v1/payment-widget';
    script.async = true;
    // 스크립트 로드 완료 후 처리
    script.onload = () => {
      const tossPayment = window?.PaymentWidget;
      if (tossPayment) {
        const widget = tossPayment(widgetClientKey, customerKey);
        setPaymentWidget(widget); // 상태 업데이트
      }
    };

    // head에 스크립트를 추가
    document.head.appendChild(script);

    // 컴포넌트 언마운트 시 스크립트 제거
    return () => {
      document.head.removeChild(script);
    };
  }, [widgetClientKey, customerKey]);

  const { data: orderFormData, refetch: refetchFormData } = useFetchOrderForm({
    preorder_idx: preorderId ?? '',
  });
  const { data: orderOptionListData, refetch: refetchOptionList } = useFetchOrderOptions({
    preorder_idx: preorderId ?? '',
  });
  const { mutate: mutateOrder } = useMutationOrder();

  useEffect(() => {
    if (!isAddressModalOpen) {
      refetchFormData();
      refetchOptionList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddressModalOpen]);

  useLayoutEffect(() => {
    if (paymentWidget) {
      paymentWidget.renderPaymentMethods(
        '#payment-method',
        { value: orderFormData?.order?.total_payment_amount, currency: 'KRW', country: 'KR' },
        { variantKey: 'DEFAULT' },
      );
    }
  }, [paymentWidget, orderFormData]);

  const closeAlert = () =>
    setAlertState({
      isAlertVisible: false,
      header: [''],
      body: [''],
      buttons: [{ title: '', handleClick: () => {}, style: 'primary' }],
    });

  const handleClickOrder = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!orderFormData?.order?.ship_address) {
      return handleAddressModalOpen();
    } else if (!orderFormData?.order?.ship_contact1) {
      setAlertState({
        isAlertVisible: true,
        header: [''],
        body: ['연락처를 입력해 주세요.'],
        buttons: [
          {
            title: '확인',
            handleClick: () => {
              closeAlert();
              handleAddressModalOpen();
            },
            style: 'primary',
          },
        ],
      });
      return;
    } else {
      try {
        const localStorageToken = localStorage.getItem(AUTH_TOKEN_KEY);

        const newTokenData = await refreshAccessToken();

        localStorageToken
          ? localStorage.setItem(AUTH_TOKEN_KEY, newTokenData?.data?.access_token)
          : sessionStorage.setItem(AUTH_TOKEN_KEY, newTokenData?.data?.access_token);

        handleMixpanelEvent('click_purchase_product_store', {});
        mutateOrder(preorderId, {
          onSuccess: data => {
            if (data?.order_id) {
              paymentWidget.requestPayment({
                orderId: data?.order_id,
                orderName: orderFormData?.order?.order_name,
                successUrl: window.location.origin + PATH.ORDER_COMPLETE,
                failUrl: window.location.origin + `${PATH.ORDER}?id=${preorderId}`,
                // customerEmail: 'customer123@gmail.com',
                // customerName: '김토스',
                // customerMobilePhone: '01012341234',
              });
            } else {
              console.log(data);
            }
          },
          onError: err => {
            console.log(err);
          },
        });
      } catch (e: any) {
        if (e?.response?.status === 401) {
          removeSessionStorage(AUTH_TOKEN_KEY);
          removeLocalStorage(AUTH_TOKEN_KEY);
          const beforeUrl = location.pathname + location.search;
          toast.error('로그인이 만료되었습니다.\n 다시 로그인 해주세요.');
          navigate(`${PATH.LOGIN}?before_url=${encodeURIComponent(beforeUrl)}`);
        }
      }
    }
  };
  const handleAddressModalOpen = () => setIsAddressModalOpen(true);
  const handleAddressModalClose = () => setIsAddressModalOpen(false);

  const handleClickDetail = () => {
    window.open(PRIVACY_AGREEMENT_URL, '_blank')?.focus();
  };

  return (
    <>
      <section className="desktop-jumbotron">
        <img src={paymentImg} alt="주문/결제" />
        <span className="text">주문/결제</span>
      </section>
      {/* <!-- 주문/결제/배송내역 Bottom-line 박스 --> */}
      {/* <!-- 배송정보 --> */}
      {/* 배송지 변경 Modal */}
      <div className="section-contents">
        {isAddressModalOpen && (
          <DeliveryAddressModal
            isOpen={isAddressModalOpen}
            handleClickAddressModalToggle={handleAddressModalClose}
            from="order"
            addressId={orderFormData?.order?.ship_address}
          />
        )}
        <div className="section-title with-desktop-btn">
          <div className="label">배송정보</div>
          <button type="button" className="btn btn-sm btn-outline" onClick={handleAddressModalOpen}>
            {orderFormData?.order?.ship_recipient_name && orderFormData?.order?.ship_address
              ? '배송지 변경'
              : '배송지 등록'}
          </button>
        </div>
        {orderFormData?.order?.ship_recipient_name && orderFormData?.order?.ship_address ? (
          <div className="selected-address">
            <div className="border-wrap">
              <div className="row">
                <div className="label">받는 분</div>
                <div className="text name">{orderFormData?.order?.ship_recipient_name}</div>
                <span className="badge">기본 배송지</span>
                <button
                  type="button"
                  className="btn btn-sm btn-outline"
                  onClick={handleAddressModalOpen}
                >
                  배송지 변경
                </button>
              </div>
              <div className="row address">
                <div className="label">배송지</div>
                <div className="text">
                  [{orderFormData?.order?.ship_postal_code}] {orderFormData?.order?.ship_address}{' '}
                  {orderFormData?.order?.ship_detailed_address}
                </div>
              </div>
              <div className="row">
                <div className="label">연락처</div>
                <div className="text">{orderFormData?.order?.ship_contact1}</div>
              </div>
            </div>
            <div className="row">
              <div className="label">배송 요청사항</div>
              <div className="text">{orderFormData?.order?.ship_memo ?? ''}</div>
            </div>
          </div>
        ) : (
          <div className="selected-address">
            <div className="border-wrap">
              <div className="row none">
                <div className="text">배송지 정보가 없습니다.</div>
                <button
                  type="button"
                  className="btn btn-sm btn-outline"
                  onClick={handleAddressModalOpen}
                >
                  배송지 추가
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <!-- 주문상품 --> */}
      <div className="section-contents">
        <div className="section-title with-desktop-btn">
          <div className="label">
            주문상품<span className="value">({orderFormData?.order?.order_option_cnt}건)</span>
          </div>
        </div>
        <ul className="selected-payment-list">
          {orderOptionListData?.seller_sections?.map((seller: WoPreorderSellerSection) => (
            <li className="payment-item" key={seller?.seller_idx}>
              <article className="border-wrap">
                <div className="title-col mobile-border-bottom">
                  <div className="title">{seller?.preorder_options?.[0]?.brand_name}</div>
                  <div className="shipping-info">
                    배송비{' '}
                    {seller?.real_shipping_fee
                      ? `${numberFormat(seller?.real_shipping_fee ?? 0)}원`
                      : '무료'}
                  </div>
                </div>
                <ul className="selected-store-list">
                  {seller?.preorder_options?.map(option => {
                    return (
                      <li
                        className="store-item"
                        key={`${option?.preorder_option_idx}_${option?.preorder_idx}`}
                      >
                        <div className="product-item horizontal">
                          <div className="thumbnail">
                            <img src={option?.main_image_url} alt="제품이미지" />
                          </div>
                          <div className="info-wrap desktop-inline">
                            <div className="col">
                              <p className="title">{option?.product_name}</p>
                              <p className="option">{option?.order_option_name}</p>
                            </div>
                            <div className="col all-inline">
                              <div className="inner-col">
                                <div className="label">상품금액</div>
                                <div className="pricing">
                                  {numberFormat(option?.order_option_tot_amount)}
                                  <span className="unit">원</span>
                                </div>
                              </div>
                              <div className="inner-col amount-col">
                                <div className="label">수량</div>
                                <div className="amount">
                                  {option?.quantity}
                                  <span className="unit">개</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </article>
            </li>
          ))}
        </ul>
      </div>
      {/*<!-- 쿠폰사용 & 포인트 -->
         <div className="section-contents-wrap">
           쿠폰사용 -->
        <div className="section-contents">
          <div className="section-title with-coupon">
            <div className="label">쿠폰사용</div>
            <div className="coupon">
              (사용 가능 <span>2</span>장 / 전체 <span>5</span>장)
            </div>
          </div>
          <div className="dropdown">
           드롭다운을 눌렀을 때 menu-open 클래스 붙여 스타일 변경함 -->
            <button className="btn btn-outline btn-md dropdown-toggle" type="button">
              사용가능 2장 / 전체 5장
            </button>
           화면 크기 lg ~ dropdown -->
           show 클래스를 붙여 드롭다운 메뉴를 표시함 -->
            <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item active" to="#">
                  20% 할인 - 3월 쿠폰 (10만원 이상)
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  무료배송 - 첫 구매 감사
                </Link>
              </li>
              <li>
                <Link className="dropdown-item soldout" to="#">
                  10,000원 할인 - 3월 쿠폰 (20만원 이상)
                </Link>
              </li>
            </ul>
          </div>
        </div>
           모바일 쿠폰 바텀시트 -->
        <div style={{ display: 'none' }} id="load-coupon-modal"></div>
           포인트 -->
        <div className="section-contents">
          <div className="section-title with-point">
           포인트가 없을 때 point 클래스에 disabled 클래스 붙이기 -->
            <div className="label">
              포인트 <span className="point disabled">0P</span>
            </div>
            <label className="checkbox-group green-box sm-box">
              <input type="checkbox" name="test-1" />
              <span className="label">전액 사용</span>
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="forms-group">
            <input type="number" className="form-control" placeholder="0" disabled />
            <button type="button" className="btn btn-md btn-outline">
              사용
            </button>
          </div>
        </div>
      </div> */}
      {/* <!-- 결제정보 --> */}
      <div className="section-contents">
        <div className="section-title with-desktop-btn">
          <div className="label">결제정보</div>
        </div>
        <div className="payment-table">
          <div className="row">
            <div className="label">상품금액</div>
            <div className="price">
              {numberFormat(orderFormData?.order?.product_tot_amount)}
              <span className="unit">원</span>
            </div>
          </div>
          <div className="row">
            <div className="label">할인금액</div>
            <div className="price">
              -{numberFormat(orderFormData?.order?.event_discount)}
              <span className="unit">원</span>
            </div>
          </div>
          {/* <div className="row sub-row">
            <div className="label">ㄴ 쿠폰 사용</div>
            <div className="price">
              -500<span className="unit">원</span>
            </div>
          </div> */}
          <div className="row shipping-info">
            <div className="label">배송비</div>
            <div className="price">
              {numberFormat(orderFormData?.order?.shipping_fee)}
              <span className="unit">원</span>
            </div>
          </div>
          {!!orderFormData?.order?.remote_area_shipping_fee && (
            <div className="row">
              <div className="label">도서산간 배송비</div>
              <div className="price">
                {numberFormat(orderFormData?.order?.remote_area_shipping_fee)}
                <span className="unit">원</span>
              </div>
            </div>
          )}
          <div className="row total">
            <div className="label">최종 결제금액</div>
            <div className="price">
              {numberFormat(orderFormData?.order?.final_payment_amount)}
              <span className="unit">원</span>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- 결제수단 --> */}
      <div className="section-contents">
        <div className="section-title with-desktop-btn">
          <div className="label">결제수단</div>
          {/* <!-- 데스크탑에서만 나타나는 결제수단 저장버튼 --> */}
          {/* <label className="checkbox-group green-box sm-box">
            <input type="checkbox" name="test-1" />
            <span className="label">이 결제수단을 다음에도 사용</span>
            <span className="checkmark"></span>
          </label> */}
        </div>
        {/* <!-- 모바일에서만 나타나는 결제수단 저장버튼 --> */}
        {/* <div className="mobile-option-checkbox">
          <label className="checkbox-group green-box sm-box">
            <input type="checkbox" name="test-1" />
            <span className="label">이 결제수단을 다음에도 사용</span>
            <span className="checkmark"></span>
          </label>
        </div> */}
        <div className="wrapper w-100">
          <div className="max-w-540 w-100">
            <div id="payment-method" className="w-100"></div>
            <div id="agreement" className="w-100"></div>
          </div>
          {/* {errorMessage !== null && <Index message={errorMessage} duration={1500} />} */}
        </div>
      </div>
      {/* <!-- 정보 제공 동의 및 결제버튼 --> */}
      <div className="section-contents">
        <div className="payment-notice">
          <div className="text">주문 내용을 확인했으며 정보제공에 동의합니다.</div>
          <button className="more" onClick={handleClickDetail}>
            자세히
          </button>
        </div>
        <div className="payment-btn-wrap">
          <button
            type="button"
            id="payment-request-button"
            className="btn btn-lg btn-filled btn-block"
            onClick={handleClickOrder}
            // disabled={!orderFormData?.order?.ship_address}
          >
            {numberFormat(orderFormData?.order?.final_payment_amount)}원 결제하기
          </button>
        </div>
      </div>
      {alertState.isAlertVisible && (
        <AlertModal
          isOpen={alertState.isAlertVisible}
          header={alertState.header}
          body={alertState.body}
          buttons={alertState.buttons}
        />
      )}
      {/* <!-- Success (참고: Success 일 때 기획 미정) --> // TODO 삭제? */}

      {/* <!-- Success 일 때 divider + 제품 추천영역 보여주기 -->
      <div style={{ display: 'none' }} className="mobile-divider"></div>
      {/* <!-- // TODO: 추천영역 horizontal(2.5열)에 클래스 mobile-mg-r-0 추가하여 패딩값 없앰 --> */}
      {/* <section style={{ display: 'none' }} className="mobile-mg-r-0">
        <div id="load-list-header-normal"></div>
        <!-- Horizontal Product list -->
        <div id="load-horizontal-prd"></div>
      </section> */}
    </>
  );
};
