import React, { ReactNode, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Toast } from '@components/common/molecules/toast';

import '@/assets/scss/main.scss';

interface StyleProviderProps {
  children?: ReactNode;
}

export const StyleProvider = ({ children }: StyleProviderProps) => {
  // modal이 떠 있을 때 배경 스크롤 안되게 함
  useEffect(() => {
    const handleModalBackgroundChange = () => {
      const modalExists = document.querySelector('.modal-background');
      if (modalExists) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    };

    const observer = new MutationObserver(handleModalBackgroundChange);

    observer.observe(document.body, { childList: true, subtree: true });

    // 초기 상태 확인
    handleModalBackgroundChange();

    return () => {
      observer.disconnect();
      document.body.style.overflow = ''; // 복구
    };
  }, []);
  return (
    <>
      {children}
      <Toaster
        position="top-center"
        reverseOrder={false}
        children={toast => {
          return <Toast message={toast.message as string} />;
        }}
      />
    </>
  );
};
