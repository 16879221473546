import { useNavigate } from 'react-router-dom';

import { DARK_STATUS, DISABLED_STATUS } from '@/constants/order';
import { PATH } from '@/constants/path';
import { WoCartItemCreateForm, WoOrderOption } from '@/generated/api/type/data-contracts';
import { getSessionStorage, handleClickTracking } from '@/utils';
import { numberFormat } from '@/utils/formats';

interface OrderDetailItemProps {
  option: WoOrderOption;
  handleAddingCart: (param: WoCartItemCreateForm) => void;
  handleClickOrderConfirm: (orderOptionIdx: number) => void;
  handleClickReview: (option: WoOrderOption) => void;
  handleInquiryClick: () => void;
  handleOpenModal: (mode: string) => void;
  handleSelectCompany: () => void;
}

export const OrderDetailItem = ({
  option,
  handleAddingCart,
  handleClickOrderConfirm,
  handleClickReview,
  handleInquiryClick,
  handleOpenModal,
  handleSelectCompany,
}: OrderDetailItemProps) => {
  const navigate = useNavigate();
  const handleClickProduct = (prdId: number) => {
    navigate(`${PATH.PRODUCT_DETAIL}/${prdId}`);
  };
  const referrerIdx = getSessionStorage('REFERRER');

  return (
    <li
      className={`store-item payCom-store-item${
        DISABLED_STATUS.includes(option?.order_status_cd ?? '') ? ' disabled' : ''
      }`}
      key={option?.order_option_idx}
    >
      {/* <!-- FIXME: 모바일에서만 보이는 상태 값 --> */}
      <div className="payCom-state">
        <span
          className={`badge-payment${
            DARK_STATUS.includes(option?.order_status_name?.split('(')?.[0] ?? '') ? ' dark' : ''
          }`}
        >
          {option?.order_status_name}
        </span>
        {['배송완료', '주문취소', '반품진행중', '교환진행중'].includes(
          option?.order_status_name ?? '',
        ) && (
          <>
            {/* // TODO */}
            <div className="state-detail">24.06.10 12:30:40</div>
            {/* 주문취소 -> <div className="state-detail">
          <span>취소 24.06.10 12:30:40</span>
        </div> */}
            {/* 환불
          <span className="alert">사유 : 파손/불량</span>
          <span>환불 24.06.10 12:30:40</span>
         */}
          </>
          // 취소접수 24.06.10 (카드 취소시 최대 3~5 영업일 소요)
        )}
      </div>
      {/* <!-- 제품 상세 --> */}
      <div className="product-item horizontal">
        {/* <label className="checkbox-group green-box sm-box">
      <input
        type="checkbox"
        name="test-1"
        disabled={['주문취소', '반품진행중', '교환진행중'].includes(
          option?.order_status_name ?? '',
        )}
        checked={selectedOptions.includes(option?.order_option_idx ?? 0)}
        onChange={() => handleSelectOption(option?.order_option_idx ?? 0)}
      />
      <span className="checkmark"></span>
    </label> */}
        <div
          className="thumbnail"
          onClick={() => option?.product_idx && handleClickProduct(option?.product_idx)}
        >
          <img src={option?.main_image_url} alt={option?.product_name} />
        </div>
        {/* <!-- FIXME: Payment 보다 infowrap이 더 단순합니다. --> */}
        <div className="info-wrap">
          {/* <!-- FIXME: 데스크탑에서만 보이는 상태 값 --> */}
          <div className="payCom-state">
            <span
              className={`badge-payment${
                DARK_STATUS.includes(option?.order_status_name ?? '') ? ' dark' : ''
              }`}
            >
              {option?.order_status_name}
            </span>
            {['배송완료', '주문취소'].includes(option?.order_status_name ?? '') && (
              <>
                {/* // TODO */}
                {/* <div className="state-detail">24.06.10 12:30:40</div> */}
                {/* 주문취소 - <div className="state-detail">
              <span>취소 24.06.10 12:30:40</span>
            </div> */}
                {/* 환불
              <div className="state-detail">
                <span className="alert">사유 : 파손/불량</span>
                <span>환불 24.06.10 12:30:40</span>
              </div>
             */}
              </>
              // 접수 24.06.10 (카드 취소시 최대 3~5 영업일 소요)
            )}
          </div>
          <p
            className="title"
            onClick={() => option?.product_idx && handleClickProduct(option?.product_idx)}
          >
            {option?.product_name}
          </p>
          <p className="option">{option?.order_option_name}</p>
          <div className="payment-price-amount-col">
            <div className="pricing">
              {numberFormat(option?.order_option_tot_amount)}
              <span className="unit">원</span>
            </div>
            <div className="amount">
              {option?.quantity}
              <span className="unit">개</span>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- FIXME: 제품 배송관리 --> */}
      <div className="btn-inline-group tracking-status-controls">
        {option?.btn_return_yn === 'Y' && (
          <button
            type="button"
            className="btn btn-sm btn-outline"
            onClick={() => {
              handleSelectCompany();
              handleOpenModal('refund');
            }}
          >
            반품요청
          </button>
        )}
        {option?.btn_return_yn === 'Y' && (
          <button
            type="button"
            className="btn btn-sm btn-outline"
            onClick={() => {
              handleOpenModal('exchange');
            }}
          >
            교환요청
          </button>
        )}
        {option?.btn_cancel_yn === 'Y' && (
          <button
            type="button"
            className="btn btn-sm btn-outline"
            onClick={() => {
              handleSelectCompany();
              handleOpenModal('cancel');
            }}
          >
            취소하기
          </button>
        )}
        {option?.btn_purchase_confirm_yn === 'Y' && (
          <button
            type="button"
            className="btn btn-sm btn-outline"
            onClick={() =>
              option?.order_option_idx && handleClickOrderConfirm(option?.order_option_idx)
            }
          >
            구매확정
          </button>
        )}
        {option?.btn_add_to_cart_yn === 'Y' && (
          <button
            type="button"
            className="btn btn-sm btn-outline"
            onClick={() =>
              handleAddingCart({
                product_idx: option?.product_idx as number,
                product_option_idx: option?.product_option_idx as number,
                quantity: 1,
                referrer_influencer_idx: Number(referrerIdx ?? 0),
              })
            }
          >
            장바구니
          </button>
        )}
        {option?.review_write_able_yn === 'Y' && (
          <button
            type="button"
            className="btn btn-sm btn-outline"
            onClick={() => handleClickReview(option)}
          >
            리뷰작성
          </button>
        )}
        {option?.btn_track_shipping_yn === 'Y' && (
          <button
            type="button"
            className="btn btn-sm btn-outline"
            onClick={() =>
              handleClickTracking(option?.shipping_comcode ?? '', option?.shipping_invoice_no ?? '')
            }
          >
            배송조회
          </button>
        )}
        {option?.btn_qna_yn === 'Y' && (
          <button type="button" className="btn btn-sm btn-outline" onClick={handleInquiryClick}>
            문의하기
          </button>
        )}
      </div>
    </li>
  );
};
