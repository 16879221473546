import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import leftArrowIc from '@/assets/ic/arrow-left.svg';
import homeIc from '@/assets/ic/home.svg';
import menuBkIc from '@/assets/ic/menu-toggle-bk.svg';
import cartBkIc from '@/assets/ic/product-box.svg';
import searchIc from '@/assets/ic/search.svg';
import { PATH, ROUTES } from '@/constants/path';
import useAuth from '@/hooks/use-auth';
import useLoginInfo from '@/hooks/use-login-info';
import useMixpanel from '@/hooks/use-mixpanel';
import { Snb } from '@/layouts/base-layout/header/snb';
import { GnbWhiteBar } from './gnb-white-bar';
import { GnbGreenBar } from './gnb-green-bar';

export const Gnb = () => {
  const { handleMixpanelEvent } = useMixpanel();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const { id } = useParams();
  const [isSnbOpen, setIsSnbOpen] = useState(false);
  const { isLogin, userToken } = useLoginInfo();
  const { refetchUserDetail } = useAuth();
  const { data: userDetailData } = useFetchGetUserDetail({
    accessToken: userToken,
  });

  const isExpanded = [PATH.HOME, PATH.FRIENDLY].includes(pathname);
  const snbToggle = () => setIsSnbOpen(pre => !pre);
  const handleTracking = (type: string) => {
    handleMixpanelEvent('click_gnb_menu', { type });
  };

  const handlePreviousBtn = () => {
    navigate(-1);
  };

  const title = ROUTES.find(
    item => item.path === (id ? location.pathname.split(id ?? '')[0] : location.pathname),
  )?.title;

  useEffect(() => {
    refetchUserDetail();
  }, []);

  return (
    <>
      <header>
        <div>
          <nav className={`navbar${isExpanded ? ' navbar-expand' : ''}`}>
            {!isExpanded && (
              <div className="title-bar">
                <div className="navbar-collapse">
                  <Link className="nav-link link-mobile" to="#" onClick={handlePreviousBtn}>
                    <img src={leftArrowIc} alt="previous page" />
                  </Link>
                  <Link
                    className="nav-link link-mobile"
                    to={PATH.HOME}
                    onClick={() => handleTracking('home')}
                  >
                    <img src={homeIc} alt="" />
                  </Link>
                </div>
                <div className="title">{title ?? ''}</div>
                <div className="navbar-collapse">
                  <Link
                    className="nav-link link-mobile"
                    to={PATH.SEARCH}
                    onClick={() => handleTracking('search')}
                  >
                    <img src={searchIc} alt="" />
                  </Link>
                  <Link
                    className="nav-link link-mobile"
                    to={PATH.CART}
                    state={{ from: 'gnb' }}
                    onClick={() => handleTracking('cart')}
                  >
                    <img src={cartBkIc} alt="" />
                    {!!userDetailData?.self_order_status?.cart_cnt && (
                      <span className="badge-amount">
                        {userDetailData?.self_order_status?.cart_cnt}
                      </span>
                    )}
                  </Link>
                  <Link
                    className="nav-link link-mobile"
                    to="#"
                    onClick={() => {
                      snbToggle();
                      handleTracking('snb');
                    }}
                  >
                    <img src={menuBkIc} alt="side bar toggle" />
                  </Link>
                </div>
              </div>
            )}
            <GnbGreenBar
              isLogin={isLogin}
              isExpanded={isExpanded}
              cartCount={userDetailData?.self_order_status?.cart_cnt ?? 0}
              orderCount={userDetailData?.self_order_status?.order_cnt ?? 0}
              handleTracking={handleTracking}
              snbToggle={snbToggle}
            />
            <GnbWhiteBar isExpanded={isExpanded} />
          </nav>
        </div>
      </header>
      {isSnbOpen && <Snb snbToggle={snbToggle} />}
    </>
  );
};
