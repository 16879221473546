import { useState } from 'react';
import { type SelectedOption } from '@components/domain/product/product-provider';

import { WoDetailedProduct } from '@/generated/api/type/data-contracts';
import useMixpanel from '@/hooks/use-mixpanel';
import { useProduct } from '@/hooks/use-product';
import { numberFormat } from '@/utils/formats';

interface OptionDropdownProps {
  productDetail: WoDetailedProduct;
  classNameAddOn?: string;
  located: 'web_top' | 'web_mid' | 'mobile_bottom';
}
export const OptionDropdown = ({ productDetail, classNameAddOn, located }: OptionDropdownProps) => {
  const { productContext, productDispatch } = useProduct();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { handleMixpanelEvent } = useMixpanel();

  const handleClickDropdown = () => {
    setIsDropdownOpen(pre => !pre);
  };

  const handleSelectOption = (item: SelectedOption) => {
    const existOption = productContext?.selectedOptions?.find(
      option => option.product_option_idx === item.product_option_idx,
    );
    if (existOption) {
      productDispatch?.setToastState('이미 선택한 옵션이에요.');
    } else {
      productDispatch?.addOption(item);
      handleMixpanelEvent('click_product_option', {
        option_name: item.option_name,
        option_id: item.product_option_idx,
        option_location: located,
      });
    }
    setIsDropdownOpen(false);
  };
  return (
    <div className="dropdown">
      <button
        className={`btn btn-outline btn-md dropdown-toggle ${isDropdownOpen ? 'menu-open' : ''}`}
        type="button"
        onClick={handleClickDropdown}
      >
        상품 선택<sup>*</sup>
      </button>

      <ul
        className={`dropdown-menu${
          !isDropdownOpen ? '' : classNameAddOn ? classNameAddOn : ' show'
        }`}
      >
        {productDetail?.product_options?.map((option, optionIdx) => {
          return (
            <li
              key={`${optionIdx}_${option?.option_name ?? 0}`}
              onClick={() => {
                option?.sold_out_yn !== 'Y' &&
                  handleSelectOption({
                    ...option,
                    optionCount: 1,
                    option_name: `${(optionIdx + 1).toString().padStart(2, '0')},${
                      option?.option_name ?? ''
                    }`,
                  });
              }}
            >
              <div className={`dropdown-item${option?.sold_out_yn === 'Y' ? ' soldout' : ''}`}>
                <span className="option">{(optionIdx + 1).toString().padStart(2, '0')}</span>
                <span className="title">{option?.option_name}</span>
                <div className="price">
                  {(!!option.discount_influencer_rate || !!option.discount_rate) && (
                    <span className="discount-percent">
                      {option.discount_influencer_rate || option.discount_rate}%
                    </span>
                  )}
                  {numberFormat(
                    option?.discounted_influencer_price ??
                      option?.discounted_price ??
                      option?.sale_price,
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
