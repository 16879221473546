import { useFetchMenu } from '@/apis/commerce/menus/useFetchMenu';
import { RenderDesktop } from '@components/domain/friendly/render-desktop';
import { RenderMobile } from '@components/domain/friendly/render-mobile';
import { useLocation } from 'react-router-dom';

export const FriendlyTemplate = () => {
  const { pathname } = useLocation();
  const typeCd = pathname.split('/')[1];
  const { data: menuData } = useFetchMenu({ options: { staleTime: 1000 * 60 * 10 } });
  const collectionCodes = menuData?.menus?.map(menu => menu.collection_type_code);
  const type = collectionCodes?.includes(pathname.split('/')[1]) ? typeCd : 'friendly';

  const isDesktop = window.innerWidth >= 1200; // pagination 이 달라 분기
  const limit = window.innerWidth >= 905 ? 20 : 12;
  const initParams = {
    type,
    value: { orderColumn: 'sale_count', order: 'desc' },
    offset: 0,
    limit,
  };

  return (
    <section>
      {isDesktop ? (
        <RenderDesktop initParams={initParams} />
      ) : (
        <RenderMobile initParams={initParams} />
      )}
    </section>
  );
};
