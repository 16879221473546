import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Link } from 'react-router-dom';

import { getBusinessAddrlink, JusoData } from '@/apis/external/business-juso';
import searchIc from '@/assets/ic/search.svg';

interface FindAddressProps {
  handleSelectAddress: (
    postCode: string,
    oldAddress: string,
    address: string,
    detail: string,
  ) => void;
}

export const FindAddress = ({ handleSelectAddress }: FindAddressProps) => {
  const isMobile = window.innerWidth < 905;
  const [searchWord, setSearchWord] = useState('');
  const [resultList, setResultsList] = useState<JusoData[] | undefined>(undefined);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  // desktop pagination start index -> 6 이상일때 1~5페이지만 보여짐
  const [DTStartIdx, setDTStartIdx] = useState(0);
  const [startIdx, setStartIdx] = useState(0);

  const totalPage = Math.floor((resultList?.length ?? 0) / 10);

  const getAddress = async () => {
    const expText = /[%=><]/;
    if (expText.test(searchWord)) {
      alert(`-10, [%=><] 해당 특수문자 입력 할수 없습니다.`);
    } else if (searchWord.length <= 1) {
      alert('검색어는 두글자 이상 입력되어야 합니다.');
    }

    const params = {
      confmKey: 'U01TX0FVVEgyMDIzMDkyNzEyNDI0MTExNDEzNDk=',
      keyword: searchWord,
      currentPage: 1,
      countPerPage: 100,
      resultType: 'json',
    };
    const res = await getBusinessAddrlink(params);

    if (res?.results?.common?.errorCode !== '0') {
      alert(res?.results?.common?.errorMessage);
    }

    setResultsList(res?.results?.juso);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const keyword = e.currentTarget.value;
    setSearchWord(keyword);
  };

  const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const keyword = e.currentTarget.value;
      setSearchWord(keyword);
      await getAddress();
    }
  };

  // admCd: 건물 관리번호
  const handleSelect = (admCd: string) => {
    setSelectedAddress(admCd);
  };

  const handleChangeDetailAddress = (e: ChangeEvent<HTMLInputElement>) => {
    const address = e.currentTarget.value;
    setDetailAddress(address);
  };
  const handleClickPageArrow = (type: 'next' | 'prev') => {
    switch (type) {
      case 'prev':
        if (startIdx >= 5) {
          setDTStartIdx(0);
          setStartIdx(0);
        }
        break;
      case 'next':
        if (startIdx < 6) {
          setDTStartIdx(5);
          setStartIdx(5);
        }
        break;
    }
  };

  return (
    <>
      <div className="modal-contents">
        <div className="row">
          <form className="forms-search" role="search">
            <input
              type="search"
              className="form-control search"
              placeholder="논현로 85길"
              value={searchWord}
              onChange={handleChangeInput}
              onKeyDown={handleKeyDown}
              autoFocus
            />
            <button
              className="search-submit"
              onClick={e => {
                e.preventDefault();
                getAddress();
              }}
            >
              <img src={searchIc} alt="주소 검색" />
            </button>
          </form>
          {resultList === undefined ? (
            <></>
          ) : (
            <>
              <div className="search-title">
                <div className="label">전체 {resultList?.length ?? 0}</div>
              </div>
              {!resultList?.length ? (
                <div className="empty-list">
                  <div className="lines">
                    <span>“검색 결과가 없어요”</span>
                    <span className="line"></span>
                  </div>
                  <div className="sub-text">
                    일치하는 내용이 없습니다.
                    <br />
                    다시 검색해 보세요.
                  </div>
                </div>
              ) : (
                <ul className="address-list-group">
                  {(!isMobile && resultList.length > 10
                    ? resultList.slice(startIdx, startIdx + 10)
                    : resultList
                  )?.map(address => (
                    <li
                      className="list-item"
                      key={address?.bdMgtSn}
                      onClick={() => handleSelect(address?.bdMgtSn)}
                    >
                      <div>
                        <span>[{address?.zipNo}]</span>
                        <span>{address?.roadAddr}</span>
                      </div>
                      <div className="prev-address">
                        <span className="badge">지번</span>
                        <span>{address?.jibunAddr}</span>
                      </div>

                      {selectedAddress === address?.bdMgtSn && (
                        <div className="forms-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="상세 주소 입력"
                            value={detailAddress}
                            onChange={handleChangeDetailAddress}
                          />
                          <button
                            type="button"
                            className="btn btn-md btn-outline"
                            onClick={() =>
                              handleSelectAddress(
                                address?.zipNo,
                                address?.jibunAddr ?? '',
                                address?.roadAddr ?? '',
                                detailAddress,
                              )
                            }
                          >
                            사용
                          </button>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
          {/* <!-- 주소 검색 전 예시 --> */}
          {(resultList === undefined || !resultList?.length) && (
            <ul className="search-example">
              <li>
                도로명 + 건물번호 <span>(예: 논현로 85길)</span>
              </li>
              <li>
                지역명(읍/면/동/리) + 지번 <span>(예: 역삼동 736)</span>
              </li>
              <li>
                시/군/구 + 도로명, 동명 또는 건물명(아파트명) <span>(예: 역삼동 동경빌딩)</span>
              </li>
            </ul>
          )}
        </div>
      </div>
      {(resultList?.length ?? 0) > 10 && (
        <div className="mobile-bottom-nav">
          <div className="pagenation-group">
            <ul className="pagenation">
              {totalPage > 5 && (
                <li>
                  <Link
                    to="#"
                    className="btn btn-outline btn-rounded"
                    onClick={() => handleClickPageArrow('prev')}
                  />
                </li>
              )}
              {(totalPage < 6
                ? new Array(totalPage ?? 1).fill(false)
                : Array.from({ length: totalPage }, (_, i) => i + 1).slice(
                    DTStartIdx,
                    totalPage < DTStartIdx + 5 ? totalPage : DTStartIdx + 5,
                  )
              ).map((page, idx) => {
                return (
                  <li key={`resultList_page_${idx}`}>
                    <Link
                      to="#"
                      className={`page${page === startIdx + 1 ? ' active' : ''}`}
                      onClick={() => setStartIdx(page - 1)}
                    >
                      {page}
                    </Link>
                  </li>
                );
              })}
              {totalPage > 5 && (
                <li>
                  <Link
                    to="#"
                    className="btn btn-outline btn-rounded right"
                    onClick={() => handleClickPageArrow('next')}
                  />
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
