import { useQuery } from '@tanstack/react-query';
import { get } from '@/apis/api';
import { QueryOptionsType } from '@/generated/api/type/api';

interface Props {
  redirectUrl: string;
  options?: QueryOptionsType<any>;
}

export function useFetchPassUrl({ redirectUrl }: Props) {
  const getPassUrl = () => get(`/user/pass?return_url=${redirectUrl}`).then(res => res?.data);

  return useQuery({ queryKey: ['useFetchPassUrl'], queryFn: getPassUrl });
}
