import Lottie from 'react-lottie';

import loadingImg from '@/assets/img/loading-light-green.json';
import { HTMLAttributes } from 'react';

export const LoaderImg = ({ wrapperStyles }: { wrapperStyles: HTMLAttributes<HTMLDivElement> }) => {
  const defaultOptions = {
    loop: true,
    Autoplay: true,
    animationData: loadingImg,
  };
  return (
    <div className="loader" style={{ position: 'absolute', ...wrapperStyles }}>
      <Lottie options={defaultOptions} width={400} height={400} />
    </div>
  );
};
