import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';
import { WoPreorderCreatePostReq } from '@/generated/api/type/data-contracts';

/**
 * 상품 상세에서 '주문하기'
 * @param param0
 * @returns
 */
export function useMutationPreorder() {
  const orderCart = async ({
    referrer_user_idx,
    referrer_influencer_idx,
    preorder_items,
  }: // referrer_tag_idx,
  WoPreorderCreatePostReq) => {
    const { data } = await post('/api/commerce/v1/preorders/create', {
      referrer_user_idx: referrer_user_idx || null,
      referrer_influencer_idx: referrer_influencer_idx || null,
      preorder_items,
      // referrer_tag_idx,
    });
    return data;
  };
  return useMutation({ mutationFn: orderCart });
}
