import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertModal } from '@components/common/molecules/modal/alert-modal';
import { Toast } from '@components/common/molecules/toast';
import { ProductContent } from '@/components/domain/product/product-detail/product-content';
import { ProductTop } from '@/components/domain/product/product-detail/product-top';
import { OrderBarMobile } from '@components/domain/product/common/order-bar-mobile';
import { ProductProvider } from '@components/domain/product/product-provider';

import { useFetchProductDetail } from '@/apis/commerce/products/useFetchProductDetail';
import useMixpanel from '@/hooks/use-mixpanel';
import { useSearchUrl } from '@/hooks/use-search-url';
import { getSessionStorage, setSessionStorage } from '@/utils';

/**
 * 상품 상세 페이지 (/product/:id)
 */
export const ProductDetail = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState('상세설명');
  const handleClickTab = (tab: string) => {
    setCurrentTab(tab);
  };

  const referrerSession = getSessionStorage('REFERRER');
  // 상세 도달했을때 상품 id session에 저장해뒀다가 홈으로가기 대신 첫페이지로 이동시 사용
  const productSession = getSessionStorage('rct_p'); // 최근 본 상품
  const { id } = useParams();
  const [toastState, setToastState] = useState({ title: '', duration: 500, isShow: false });
  const [alert, setAlert] = useState({
    isOpen: false,
    header: [''],
    body: [''],
    btns: [{ title: '', handleClick: () => {} }],
  });
  const { getStateBySearchParams } = useSearchUrl();
  const referrerIdx = getStateBySearchParams('referrerIdx') ?? '';
  const pharmacistNickname = getStateBySearchParams('pharmacist_nickname') ?? '';
  const fromUrl = getStateBySearchParams('from') ?? '';
  const pharmacistCd = getStateBySearchParams('pharmacist_title_code') ?? '';

  const admin = getStateBySearchParams('admin') === 'true';
  const { handleMixpanelEvent } = useMixpanel();
  const { data: productData, isFetched } = useFetchProductDetail({
    productIdx: id ?? referrerSession ?? '',
    isAdmin: admin,
    referrerIdx,
  });

  useEffect(() => {
    isFetched &&
      handleMixpanelEvent('view_detail_product', {
        influencer_id: productData?.detailed_product?.influencer_idx,
        pharmacist_nickname: pharmacistNickname,
        from: fromUrl,
        product_id: id,
        fp_product_id: productData?.detailed_product?.product_id,
        pharmacist_title_code: pharmacistCd,
      });
  }, [productData]);

  useEffect(() => {
    if (productSession) {
      const isIncludedCurrentPrd = productSession.includes(id);
      !isIncludedCurrentPrd &&
        isFetched &&
        productData &&
        setSessionStorage('rct_p', [...productSession, id]);
    } else setSessionStorage('rct_p', [id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (id === undefined || (isFetched && productData?.response?.status === 404))
    return (
      <AlertModal
        isOpen={true}
        body={['존재하지 않는 상품입니다']}
        buttons={[{ title: '뒤로가기', handleClick: () => navigate(-1) }]}
        closeAlert={() => {}}
      />
    );

  const handleCloseToast = () => {
    setToastState({ title: '', duration: 300, isShow: false });
  };

  const onSuccessAddingCart = (title: string, duration: number = 1000) => {
    setToastState({ title, duration, isShow: true });
  };

  const handleCopyUrl = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const url = window.location.href;
    await navigator.clipboard.writeText(url).then(() => {
      setToastState({ isShow: true, title: '구매 링크가 복사되었어요', duration: 1000 });
    });
  };

  const handleAlert = ({
    isOpen,
    header,
    body,
    btns,
  }: {
    isOpen: boolean;
    header: string[];
    body: string[];
    btns: { title: string; handleClick: () => void }[];
  }) => {
    setAlert({
      isOpen,
      header,
      body,
      btns,
    });
  };

  return (
    <ProductProvider>
      {productData?.detailed_product?.referrer_influencer_profile_img &&
        productData?.detailed_product?.referrer_influencer_pharmacy_name && (
          <div className="closure-code-info">
            <div className="avatar">
              <img
                src={productData?.detailed_product?.referrer_influencer_profile_img}
                alt={productData?.detailed_product?.referrer_influencer_pharmacy_name}
              />
            </div>
            <div className="code-name">
              {productData?.detailed_product?.referrer_influencer_pharmacy_name ?? ''}
            </div>
          </div>
        )}
      <ProductTop
        handleCopyUrl={handleCopyUrl}
        handleClickTab={handleClickTab}
        productDetail={productData?.detailed_product}
        onSuccessAddingCart={onSuccessAddingCart}
        handleAlert={handleAlert}
      />
      <div className="mobile-divider" />
      <ProductContent
        handleAlert={handleAlert}
        currentTab={currentTab}
        handleClickTab={handleClickTab}
        productDetail={productData?.detailed_product}
        onSuccessAddingCart={onSuccessAddingCart}
      />
      <div className="mobile-divider" />
      <OrderBarMobile
        handleAlert={handleAlert}
        productDetail={productData?.detailed_product}
        onSuccessAddingCart={onSuccessAddingCart}
      />
      {toastState.isShow && (
        <div>
          <Toast
            message={toastState.title}
            duration={toastState.duration}
            handleClickClose={handleCloseToast}
            toastType="checked"
          />
        </div>
      )}
      {alert.isOpen && (
        <AlertModal isOpen={true} body={alert.body} buttons={alert.btns} header={alert.header} />
      )}
    </ProductProvider>
  );
};
