import { ReactNode, useLayoutEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import { isProduction } from '@/utils';
import { useFetchGetUserInfo } from '@/apis/friendly-pharmacist/user/useFetchGetUserInfo';
import { AUTH_TOKEN_KEY } from '@/constants/auth';

interface TrackProviderProps {
  children: ReactNode;
}

export const MixpanelProvider = ({ children }: TrackProviderProps) => {
  const MIXPANEL_TOKEN = isProduction
    ? process.env.REACT_APP_MIXPANEL_LIVE_TOKEN
    : process.env.REACT_APP_MIXPANEL_DEV_TOKEN ?? '';
  const userToken =
    sessionStorage.getItem(AUTH_TOKEN_KEY) ?? localStorage.getItem(AUTH_TOKEN_KEY) ?? '';

  const { data: userInfoData } = useFetchGetUserInfo({
    accessToken: userToken ?? '',
    options: { enabled: !!userToken },
  });

  useLayoutEffect(() => {
    if (!MIXPANEL_TOKEN) {
      console.error('Mixpanel token is missing or invalid. Initialization skipped.');
      return;
    }
    try {
      mixpanel.init(MIXPANEL_TOKEN, {
        debug: !isProduction,
        opt_out_tracking_by_default: false,
      });
      userInfoData?.user_info?.id && mixpanel.identify(userInfoData?.user_info?.id);
    } catch (e) {
      console.error(e);
    }
  }, [MIXPANEL_TOKEN, userInfoData?.user_info?.id]);

  return children;
};
