import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY } from '@/constants/auth';
import { PATH } from '@/constants/path';
import { getSessionStorage } from '@/utils';
// import { useMutationLogout } from '@/apis/friendly-pharmacist/user/useMutationLogout';
// import { httpClient } from '@/apis/api';

export default function useLoginInfo() {
  const navigate = useNavigate();
  const recentViewedPrds = getSessionStorage('rct_p') || null;
  const userToken =
    sessionStorage.getItem(AUTH_TOKEN_KEY) ?? localStorage.getItem(AUTH_TOKEN_KEY) ?? '';
  const refreshToken =
    sessionStorage.getItem(AUTH_REFRESH_TOKEN_KEY) ??
    localStorage.getItem(AUTH_REFRESH_TOKEN_KEY) ??
    '';

  const isLogin = useMemo(() => {
    return !!userToken;
  }, [userToken]);
  // const { mutate: mutateLogout } = useMutationLogout();
  const logout = () => {
    // mutateLogout(
    //   { refreshToken },
    //   {
    //     onSuccess: res => {
    sessionStorage.removeItem(AUTH_TOKEN_KEY);
    sessionStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
    //       console.log(res);
    //       httpClient.interceptors.response.use(
    //         config => {
    //           delete config.headers.Authorization;
    //           return config;
    //         },
    //         error => Promise.reject(error),
    //       );
    //     },
    //   },
    // );
    const referrerIndex = getSessionStorage('REFERRER');
    const navigateUrl = recentViewedPrds?.[0]
      ? `${PATH.PRODUCT_DETAIL}/${recentViewedPrds[0]}${
          referrerIndex ? `?referrerIdx=${referrerIndex}` : ''
        }`
      : PATH.LOGIN;
    navigate(navigateUrl);
  };

  return { isLogin, userToken, logout };
}
