import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { OptionDropdown } from '@components/domain/product/common/option-dropdown';
import { OrderBtnBox } from '@components/domain/product/common/order-btn-box';
import { SelectedOptionItem } from '@components/domain/product/common/selected-option-item';

import { useMutationPreorder } from '@/apis/commerce/preorders/useMutationPreorder';
import productBoxIc from '@/assets/ic/product-box.svg';
import { PATH } from '@/constants/path';
import { WoDetailedProduct } from '@/generated/api/type/data-contracts';
import useLoginInfo from '@/hooks/use-login-info';
import { useProduct } from '@/hooks/use-product';
import { getSessionStorage, setSessionStorage } from '@/utils';
import { numberFormat } from '@/utils/formats';

interface OrderBarMobileProps {
  productDetail?: WoDetailedProduct;
  onSuccessAddingCart: (title: string, duration: number) => void;
  handleAlert: (alert: {
    isOpen: boolean;
    header: string[];
    body: string[];
    btns: { title: string; handleClick: () => void }[];
  }) => void;
}

export const OrderBarMobile = ({
  productDetail,
  onSuccessAddingCart,
  handleAlert,
}: OrderBarMobileProps) => {
  const { pathname, search } = useLocation();
  const { isLogin } = useLoginInfo();
  const navigate = useNavigate();
  const referrerIdx = getSessionStorage('REFERRER');
  const { productContext } = useProduct();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate: mutateOrder } = useMutationPreorder();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const redirectToOrder = () => {
    const selectedOptions =
      productContext?.selectedOptions?.map(option => ({
        product_option_idx: option?.product_option_idx,
        quantity: option?.optionCount,
      })) ?? [];
    if (isLogin) {
      selectedOptions?.length > 0
        ? mutateOrder(
            {
              referrer_influencer_idx: Number(referrerIdx) ?? 0,
              preorder_items: selectedOptions,
            },
            {
              onSuccess: data => {
                navigate(`${PATH.ORDER}?id=${data?.preorder_idx}`, { state: { from: 'direct' } });
              },
              onError: err => console.log(err.message),
            },
          )
        : toggleModal();
    } else {
      handleAlert({
        isOpen: true,
        header: [''],
        body: ['로그인이 필요한 서비스에요.', '지금 로그인 하시겠어요?'],
        btns: [
          {
            title: '로그인',
            handleClick: () => {
              setSessionStorage('prd_opts', selectedOptions);
              const beforeUrl = pathname + search;
              navigate(`${PATH.LOGIN}?before_url=${encodeURIComponent(beforeUrl)}`);
            },
          },
        ],
      });
    }
  };
  const sheetRef: any = useRef(null);
  return (
    <>
      <BottomSheet
        ref={sheetRef}
        open={isModalOpen}
        className="for-payment"
        snapPoints={({ height, minHeight, maxHeight }) => {
          console.log({ height, minHeight, maxHeight });
          return [200, Math.min(Math.max(height, minHeight), maxHeight - 100)];
        }}
        onDismiss={closeModal}
        defaultSnap={({ snapPoints, lastSnap }) => lastSnap ?? snapPoints[1]}
        header={<div className="handle" />}
        style={
          {
            '--data-rsbs-backdrop': isModalOpen ? 'rgba(0, 0, 0, 0.349)' : '',
          } as React.CSSProperties
        }
        onSpringStart={() => {
          console.log('Transition from: ', sheetRef.current.height);
          requestAnimationFrame(() => console.log('transition to: ', sheetRef.current.height));
        }}
      >
        {productDetail && (
          <div className="modal-contents">
            <div className="product-option-wrap">
              <OptionDropdown
                located="mobile_bottom"
                productDetail={productDetail}
                classNameAddOn={
                  productDetail?.product_options && productDetail?.product_options?.length > 9
                    ? ' scroll show' // 10개 이상인 경우 스크롤되도록
                    : ''
                }
              />
              <SelectedOptionItem />
              <ShippingInfo productDetail={productDetail} />
            </div>
          </div>
        )}
      </BottomSheet>
      <div className="mobile-bottom-nav">
        <div className="btn-inline-group">
          <button type="button" className="btn btn-md btn-outline btn-block" onClick={openModal}>
            <img src={productBoxIc} alt="" />
            장바구니
          </button>
          <button
            type="button"
            className="btn btn-md btn-filled btn-block"
            onClick={redirectToOrder}
          >
            바로 구매
          </button>
        </div>
      </div>
      {/* {!isModalOpen ? (
      ) : (
        <div>
          <div className="modal-background" onClick={closeModal}>
            <div
              className="modal-bottom for-payment"
              onClick={e => {
                // 내부 element 눌렀을 때 닫힘 방지
                e.stopPropagation();
              }}
            >
              <div className="handle"></div>
              {productDetail && (
                <div className="modal-contents">
                  <div className="product-option-wrap">
                    <OptionDropdown
                      located="mobile_bottom"
                      productDetail={productDetail}
                      classNameAddOn={
                        productDetail?.product_options && productDetail?.product_options?.length > 9
                          ? ' scroll show' // 10개 이상인 경우 스크롤되도록
                          : ''
                      }
                    />
                    <SelectedOptionItem />
                    <ShippingInfo productDetail={productDetail} />
                  </div>
                </div>
              )}
              <div className="mobile-bottom-nav">
                <OrderBtnBox
                  handleAlert={handleAlert}
                  located="mobile_bottom"
                  options={productDetail?.product_options}
                  isSoldOut={productDetail?.sold_out_yn === 'Y'}
                  onSuccessAddingCart={onSuccessAddingCart}
                  toggleModal={toggleModal}
                />
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

const ShippingInfo = ({ productDetail }: { productDetail: WoDetailedProduct }) => {
  return (
    <div className="shipping-info">
      <div className="row">
        <div className="header">배송정보</div>
        <div className="contents">
          <div>
            {productDetail?.shipping_estimated_duration}
            <br />
            {productDetail?.shipping_fee_text}
            <br />※ 제주/도서 산간 지역 추가 배송비 (
            {numberFormat(productDetail?.shipping_additional_fee_jeju)}원)
          </div>
        </div>
      </div>
    </div>
  );
};
