import { FC, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { DeliveryAddressModal } from '@components/common/molecules/modal/delivery-address-modal';

import { MySidebarDesktop } from '@/layouts/mypage-layout/my-sidebar/my-sidebar-desktop';
import { Toast } from '@/components/common/molecules/toast';

export const MypageLayout: FC = () => {
  const { pathname } = useLocation();
  const [toastState, setToastState] = useState({
    isShow: false,
    message: '',
    subMsgs: [''],
  });
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  const handleClickAddressModalToggle = () => {
    setIsAddressModalOpen(!isAddressModalOpen);
  };
  const handleClickAddressManage = () => {
    handleClickAddressModalToggle();
  };
  const handleToast = (message: string) => {
    setToastState({
      ...toastState,
      isShow: true,
      message,
    });
  };

  const closeToast = () => setToastState({ ...toastState, isShow: false });
  return (
    <>
      <MySidebarDesktop pathname={pathname} handleClickAddressManage={handleClickAddressManage} />
      <Outlet />
      {isAddressModalOpen && (
        <DeliveryAddressModal
          isOpen={isAddressModalOpen}
          handleClickAddressModalToggle={handleClickAddressModalToggle}
          from="mypage"
          handleToast={handleToast}
        />
      )}
      {toastState.isShow && <Toast message={toastState.message} handleClickClose={closeToast} />}
    </>
  );
};
