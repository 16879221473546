import { ExistAccount } from '@components/common/organism/auth/exist-account';
import { LoadingEntire } from '@components/common/organism/auth/loading-entire';
import { RegisterForm } from '@components/domain/register/register-form';
import { RegisterTermsModal } from '@components/domain/register/register-terms-modal';

import emailIc from '@/assets/ic/email-simple.svg';
import appleIc from '@/assets/ic/apple.svg';
import kakaoIc from '@/assets/ic/kakao-simple.svg';
import useAuth from '@/hooks/use-auth';
import { useAuthCallback } from '@/hooks/use-auth-callback';

// import pointIc from '@/assets/ic/join-point.svg';
// import couponIc from '@/assets/ic/join-coupon.svg';
// import starIc from '@/assets/ic/join-star.svg';

interface RegisterTemplateProps {}

/**
 * - `GET /user/pass` 로 패스 인증용 링크를 가져온다.
 * - `POST /user/pass/validation` 으로 패스 토큰값을 보내 패스 인증을 확인받는다.
 * -  카카오 로그인
 * - `POST /user/signup/social`로 가입을 시도한다. → 가입 성공 시 토큰 값을 리턴하여, 바로 로그인할 수 있다.
 * - `POST /user/login/social`을 통해 로그인 가능
 * - `GET /user/info` 시 가입한 계정 정보를 조회할 수 있음
 * @constructor
 */
export const RegisterTemplate = ({}: RegisterTemplateProps) => {
  const {
    isLoading,
    selectedItems,
    registerType,
    existingAccount,
    isTermsModalOpen,
    setRegisterType,
    handleOpenTermsModal,
    handleCloseTermsModal,
    handlePASSCertification,
    kakaoLogin,
    onSuccessKakaoLoginToApiRequest,
    connectCert,
  } = useAuth();

  /**
   * 카카오, 패스 인증을 하고 콜백페이지에서 전달된 성공데이터를 가지고
   * 로직을 수행한다.
   */
  useAuthCallback({
    onSuccessKakaoLoginToApiRequest,
    connectCert,
  });

  const handleOpenModal = (type: 'kakao' | 'email' | 'apple' | 'exist') => {
    setRegisterType(type);
    if (type === 'kakao') {
      kakaoLogin();
    } else {
      !isTermsModalOpen && handleOpenTermsModal();
    }
  };

  // 패스 인증후 => 이미 가입된 계정이 있을 경우
  if (registerType === 'exist' && existingAccount) {
    return <ExistAccount existingAccount={existingAccount} />;
  }

  return (
    <>
      {/*회원가입 첫 진입 UI*/}
      {registerType !== 'email' && (
        <>
          <div className="jumbotron">
            <div className="title">
              친한스토어에
              <br />
              오신 것을 환영합니다!
            </div>
            <span className="sub-text">친한스토어 가입하고 다양한 혜택을 받으세요!</span>
          </div>
          <div className="login-contents-wrap">
            <div className="action-group">
              <button className="login-action kakao" onClick={() => handleOpenModal('kakao')}>
                <img src={kakaoIc} alt="" />
                카카오로 시작하기
              </button>
              <button className="login-action" onClick={() => handleOpenModal('email')}>
                <img src={emailIc} alt="" />
                이메일로 시작하기
              </button>
              <button className="login-action apple" onClick={() => handleOpenModal('apple')}>
                <img src={appleIc} alt="" />
                Apple로 로그인
              </button>
            </div>
          </div>
          {/* <RegisterBottom /> */}
        </>
      )}

      {/* 이메일로 시작하기 버튼 클릭 => 가입 폼 */}
      {registerType === 'email' && (
        <RegisterForm
          onToggleTermsModal={(hasToken: boolean) =>
            !hasToken ? handleCloseTermsModal() : handleOpenTermsModal()
          }
          selectedItems={selectedItems}
        />
      )}

      {isTermsModalOpen && (
        <RegisterTermsModal
          isOpen={isTermsModalOpen}
          closeModal={handleCloseTermsModal}
          // setSelectedItems={setSelectedItems}
          // setExistingAccount={setExistingAccount}
          handlePASSCertification={handlePASSCertification}
        />
      )}
      {isLoading && <LoadingEntire />}
    </>
  );
};

// TODO: 정식오픈때 point 기능 추가하면 render
// const RegisterBottom = () => {
//   return (
//     <div className="login-contents-wrap">
//       <ul className="join-benefit-group">
//         <li className="list-item">
//           <img src={pointIc} alt="" />
//           <span>
//             쏫아지는
//             <br />
//             포인트!
//           </span>
//         </li>
//         <li className="list-item">
//           <img src={couponIc} alt="" />
//           <span>
//             다양한
//             <br />
//             할인쿠폰!
//           </span>
//         </li>
//         <li className="list-item">
//           <img src={starIc} alt="" />
//           <span>
//             골라보는
//             <br />
//             맞춤리뷰!
//           </span>
//         </li>
//       </ul>
//     </div>
//   );
// };
