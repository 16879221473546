import { useInfiniteQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { WoProductsGetRes } from '@/generated/api/model';

interface Props {
  initialPageParam?: number;
  limit: number;
  categoryId?: string;
  ingredientId?: string;
  brandId?: string;
  value?: { orderColumn: string; order: string };
  collectionTypeCode: string;
}

export const useInfinitelyFetchProductList = ({
  categoryId,
  ingredientId,
  brandId,
  initialPageParam = 0,
  limit,
  value,
  collectionTypeCode,
}: Props) => {
  const returnSearchParam = (key: string, value?: string) => (value ? `&${key}=${value}` : '');
  const optionalFilter = `${returnSearchParam('category_id', categoryId)}${returnSearchParam(
    'ingredient_id',
    ingredientId,
  )}${returnSearchParam('brand_id', brandId)}`;
  const fetchProducts = async (offset: number) => {
    const props = `?collection_type_code=${collectionTypeCode}&order_column=${value?.orderColumn}&order=${value?.order}&offset=${offset}&limit=${limit}${optionalFilter}`;
    const { data } = await get(`/api/commerce/v1/products${props}`);
    const returnData = data as WoProductsGetRes;
    return {
      data: returnData.products,
      nextPageOffset: offset,
      isLast: offset + limit >= data.total_count,
      total: data.total_count,
    };
  };

  const { status, data, error, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ['useInfinitelyFetchProductList', { value, categoryId, ingredientId, brandId }],
      queryFn: async ({ pageParam = initialPageParam }) => fetchProducts(pageParam),
      initialPageParam,
      getNextPageParam: (lastPage, allPages) => {
        const offset = allPages.length * limit;
        if (!lastPage.isLast) {
          return offset;
        }
        return undefined;
      },
    });

  return {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};
