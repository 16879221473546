import { PATH } from '@/constants/path';

export const AUTH_TOKEN_KEY = 'tkn';
export const AUTH_REFRESH_TOKEN_KEY = 'refresh_tkn';
export const AUTH_PASS_KEY = 'MDL_TKN';
export const AUTH_REDIRECT_URL_KEY = 'REDIRECT_URL';
export const AUTH_KAKAO_ACCESS_TOKEN = 'KAKAO_ACCESS_TOKEN';
export const AUTH_TOKEN_REFRESH_URL = '/auth/tokens/refresh';
export const AUTH_APPLE_CLIENT_ID = 'com.friendly-pharmacist.applesignin';
export const AUTH_USER_INFO_URL = '/user/info';
export const AUTH_SKIP_401 = ['/user/pass/validation', '/user/signup/social', '/user/login/email'];
export const PRIVATE_PATHS = [PATH.MYPAGE, PATH.ORDER, PATH.HELP_INQUIRY_CREATE];
