import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import { useMutatationCreateAddress } from '@/apis/commerce/self/useMutatationCreateAddress';
import { useMutationAddressModify } from '@/apis/commerce/self/useMutationAddressModify';
import { DELIVERY_MSGS } from '@/constants/order';
import { WoUserAddress, WoUserAddressInputForm } from '@/generated/api/type/data-contracts';
import useLoginInfo from '@/hooks/use-login-info';

import { FindAddress } from './find-address';
import { phoneFormat } from '@/utils/formats';

interface AddressEditorProps {
  getAddressDataAgain: () => void;
  handleChangeTitle: (title: '새 배송지 추가' | '배송지 변경' | '주소검색') => void;
  setCurrentState: Dispatch<SetStateAction<string>>;
  modifyingItem?: WoUserAddress;
}
export const AddressEditor = ({
  getAddressDataAgain,
  handleChangeTitle,
  setCurrentState,
  modifyingItem,
}: AddressEditorProps) => {
  const { userToken } = useLoginInfo();
  const { data: userDetailData } = useFetchGetUserDetail({
    accessToken: userToken,
  });

  const [selfTyping, setSelfTyping] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [addressInfo, setAddressInfo] = useState<WoUserAddressInputForm>({
    default_yn: modifyingItem?.default_yn ?? 'Y',
    ship_site_name: modifyingItem?.ship_site_name ?? '우리집',
    ship_recipient_name:
      modifyingItem?.ship_recipient_name ?? userDetailData?.self_detail?.name ?? '',
    ship_address: modifyingItem?.ship_address ?? '',
    ship_postal_code: modifyingItem?.ship_postal_code ?? '',
    ship_detailed_address: modifyingItem?.ship_detailed_address ?? '',
    ship_contact1: modifyingItem?.ship_contact1 ?? '',
    ship_contact2: modifyingItem?.ship_contact2 ?? '',
    delivery_memo: modifyingItem?.delivery_memo ?? '문 앞에 놓아주세요',
    ship_old_address: modifyingItem?.ship_old_address ?? '',
  });
  useEffect(() => {
    setSelfTyping(!DELIVERY_MSGS.includes(modifyingItem?.delivery_memo ?? ''));
    setAddressInfo;
  }, []);
  const { mutate: mutateModifyAddress } = useMutationAddressModify();
  const { mutate: mutateCreateAddress } = useMutatationCreateAddress({ addressInfo });

  useEffect(() => {
    isSearchModalOpen ? handleChangeTitle('주소검색') : handleChangeTitle('새 배송지 추가');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchModalOpen]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked, value } = e.currentTarget;

    if (name === 'default_yn') {
      setAddressInfo(pre => ({ ...pre, default_yn: checked ? 'Y' : 'N' }));
    } else if (e.currentTarget.name === 'ship_contact1') {
      const contactValue = phoneFormat(e.target.value);

      setAddressInfo(pre => ({
        ...pre,
        [name]: contactValue,
      }));
    } else {
      setAddressInfo(pre => ({
        ...pre,
        [name]: value,
      }));
    }
  };

  const handleClickDeliveryMsg = (message: string) => {
    if (message === '직접입력') {
      setSelfTyping(true);
    } else {
      setSelfTyping(false);
    }
    setAddressInfo(pre => ({ ...pre, delivery_memo: message }));
  };

  const handlePostcodeClick = () => {
    setIsSearchModalOpen(true);
  };

  const handleSelectAddress = (
    postCode: string,
    oldAddress: string,
    address: string,
    detail: string,
  ) => {
    setAddressInfo(pre => ({
      ...pre,
      ship_postal_code: postCode,
      ship_address: address,
      ship_detailed_address: detail,
      ship_old_address: oldAddress,
    }));
    setIsSearchModalOpen(false);
  };

  const handleChangeDeliveryAddress = () => {
    modifyingItem
      ? mutateModifyAddress(
          {
            addressId: modifyingItem?.address_id ?? '',
            userAddress: addressInfo,
          },
          {
            onSuccess: () => {
              getAddressDataAgain();
              setCurrentState('list');
            },
            onError: err => console.log(err.message),
          },
        )
      : mutateCreateAddress(
          { user_address: addressInfo },
          {
            onSuccess: () => {
              getAddressDataAgain();
              setCurrentState('list');
            },
            onError: err => console.log(err.message),
          },
        );
  };

  return (
    <>
      {isSearchModalOpen ? (
        <FindAddress handleSelectAddress={handleSelectAddress} />
      ) : (
        <div className="modal-contents">
          <div className="row">
            {/* <!-- 배송지명(선택) --> */}
            <div className="input-group">
              <div className="form-label">배송지명(선택)</div>
              <input
                type="text"
                className="form-control"
                placeholder="배송지 입력 ex)우리집"
                onChange={handleChangeInput}
                value={addressInfo.ship_site_name}
                name="ship_site_name"
              />
            </div>
            {/* <!-- 받는 분 --> */}
            <div className="input-group">
              <div className="form-label">받는 분</div>
              <input
                type="text"
                name="ship_recipient_name"
                onChange={handleChangeInput}
                value={addressInfo.ship_recipient_name}
                className="form-control"
                placeholder="받는 분 입력"
              />
            </div>
            {/* <!-- 연락처 --> */}
            <div className="input-group">
              <div className="form-label">연락처</div>
              <input
                name="ship_contact1"
                onChange={handleChangeInput}
                value={addressInfo.ship_contact1}
                type="numeric"
                className="form-control"
                placeholder="-없이 입력"
              />
            </div>
            {/* <!-- 주소 --> */}
            <div className="input-group address-search">
              <div className="form-label">주소</div>
              <div className="forms-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder="우편번호"
                  value={addressInfo.ship_postal_code}
                  disabled
                  onClick={handlePostcodeClick}
                  readOnly
                />
                <button
                  type="button"
                  className="btn btn-md btn-outline"
                  onClick={handlePostcodeClick}
                >
                  검색
                </button>
              </div>
              {/* <input type="text" className="form-control" placeholder="상세 주소" disabled /> */}
              <input
                type="text"
                className="form-control"
                placeholder="상세 주소"
                name="ship_address"
                value={addressInfo.ship_address}
                readOnly
                disabled
              />
              {addressInfo.ship_detailed_address && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="상세 주소"
                  name="ship_detailed_address"
                  onChange={handleChangeInput}
                  value={addressInfo.ship_detailed_address}
                />
              )}
              <label className="checkbox-group green-box">
                <input
                  type="checkbox"
                  name="default_yn"
                  checked={addressInfo.default_yn === 'Y'}
                  onChange={handleChangeInput}
                />

                <span className="label">기본 배송지로 저장</span>
                <span className="checkmark" />
              </label>
            </div>
            {/* <!-- 배송 요청사항--> */}
            <div className="input-group">
              <div className="form-label">배송 요청사항</div>
              <ul className="sort-list desktop-half-list">
                {DELIVERY_MSGS.map(msg => (
                  <li key={msg}>
                    <label className="radio-group green-active-wrap">
                      <input
                        type="radio"
                        name="delivery_memo"
                        value={msg}
                        onClick={() => handleClickDeliveryMsg(msg)}
                        checked={
                          (addressInfo.delivery_memo ?? '') === msg ||
                          (!DELIVERY_MSGS.includes(addressInfo.delivery_memo ?? '') &&
                            msg === '직접입력')
                        }
                        readOnly
                      />
                      <div className="label">
                        <div className="title">{msg}</div>
                      </div>
                      <span className="checkmark" />
                    </label>
                  </li>
                ))}
              </ul>
              {selfTyping && (
                <input
                  type="text"
                  className="form-control"
                  name="delivery_memo"
                  placeholder="최소 10자 이상 작성"
                  value={addressInfo.delivery_memo}
                  onChange={handleChangeInput}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {!isSearchModalOpen && (
        <div className="mobile-bottom-nav">
          <div className="btn-inline-group">
            <button
              type="button"
              className="btn btn-md btn-filled btn-block"
              onClick={handleChangeDeliveryAddress}
              disabled={(addressInfo.ship_contact1 ?? '')?.length < 9}
            >
              확인
            </button>

            {/* // TODO validation
          <button style="display: none;" type="button" className="btn btn-md btn-filled btn-block" disabled>확인</button> */}
          </div>
        </div>
      )}
    </>
  );
};
