import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { setSessionStorage } from '@/utils';
import { AUTH_REDIRECT_URL_KEY } from '@/constants/auth';
import { jwtDecode } from 'jwt-decode';

export const CallbackApplePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const appleData = queryString.parse(location.hash.slice(1));
  const redirectUrl = sessionStorage.getItem(AUTH_REDIRECT_URL_KEY);

  useEffect(() => {
    if (appleData) {
      setSessionStorage('apple', { code: appleData.code, token: appleData.id_token });
    }
    if (redirectUrl) {
      navigate(redirectUrl);
    }
  }, [appleData]);

  return <></>;
};
