import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

export function useMutationRegisterSocial({ onSuccess }: { onSuccess?: (data: any) => void }) {
  const register = async ({
    provider,
    email,
    snsId,
    passToken,
    appleIdToken,
    agreements,
  }: {
    provider: 'apple' | 'kakao';
    email?: string;
    snsId?: string;
    passToken?: string;
    appleIdToken?: string;
    agreements: {
      sms: boolean;
      email: boolean;
      push: boolean;
    };
  }) => {
    const response = await post('/user/signup/social', {
      provider: provider,
      email,
      sns_id: snsId,
      pass_token: passToken,
      apple_id_token: appleIdToken,
      agreements,
    });
    return { response: response.data, snsId };
  };

  return useMutation({
    mutationFn: register,
    onSuccess: data => {
      onSuccess && onSuccess(data);
    },
    onError: error => {
      console.log('Mutation failed:', error);
    },
  });
}
