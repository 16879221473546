import { useState } from 'react';
import { PrdListPaginationDesktop } from '@components/domain/main/prd-list-pagination-desktop';
import { Sort } from '@components/domain/main/sort';
import { keepPreviousData } from '@tanstack/react-query';

import { useFetchProductList } from '@/apis/commerce/products/useFetchProductList';
import { useSearchUrl } from '@/hooks/use-search-url';
import { ProductList } from '../main/product-list';

interface RenderDesktopProps {
  initParams: {
    type: string;
    value: { orderColumn: string; order: string };
    offset: number;
    limit: number;
  };
}
export const RenderDesktop = ({ initParams }: RenderDesktopProps) => {
  const { getStateBySearchParams } = useSearchUrl();
  const categoryId = getStateBySearchParams('category_id') || '';
  const ingredientId = getStateBySearchParams('ingredient_id') || '';
  const brandId = getStateBySearchParams('brand_id') || '';
  const limit = initParams.limit;
  const [offset, setOffset] = useState(0); // index
  const [order, setOrder] = useState({
    title: '판매인기순',
    value: { orderColumn: 'sale_count', order: 'asc' },
  });
  const { data: productList, isFetched: isPrdListFetched } = useFetchProductList({
    ...initParams,
    categoryId,
    ingredientId,
    brandId,
    value: order.value,
    offset: offset,
    options: { placeholderData: keepPreviousData },
  });

  const handleChangeFilter = (value: {
    title: string;
    value: { orderColumn: string; order: string };
  }) => {
    setOrder(value);
  };

  return !isPrdListFetched || productList?.products === undefined ? (
    <></>
  ) : (
    <>
      <Sort
        total={productList?.total_count ?? 0}
        selected={order.title}
        handleClickSort={handleChangeFilter}
      />
      <ProductList list={productList?.products} type="vertical" />
      <PrdListPaginationDesktop
        current={offset / limit + 1}
        total={productList?.total_count ?? 0}
        setOffset={setOffset}
        limit={limit}
      />
    </>
  );
};
