import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '@components/common/molecules/modal';

import topImg from '@/assets/ic/certification.svg';
import { ADS, REQUIRED_TERMS, TERMS_LIST } from '@/constants/terms';
import useAuth from '@/hooks/use-auth';
import useMixpanel from '@/hooks/use-mixpanel';
import { AGREEMENT_TERMS_URL, PRIVACY_AGREEMENT_URL } from '@/constants/path';
import { removeSessionStorage } from '@/utils';

interface RegisterTermsModalProps {
  closeModal: () => void;
  handlePASSCertification: () => void;
  isOpen: boolean;
}

/**
 * terms open
 * btn click: passPopup -> connectCert -> signup->loginConfirm
 */
export const RegisterTermsModal = ({
  closeModal,
  handlePASSCertification,
  isOpen,
}: RegisterTermsModalProps) => {
  const { handleMixpanelEvent } = useMixpanel();
  const { agreedList, handleChangeCheckbox, handleCheckAll, registerTypeInit } = useAuth();

  const handleCloseModal = () => {
    registerTypeInit();
    closeModal();
    removeSessionStorage('apple');
  };
  useEffect(() => {
    handleMixpanelEvent('view_agreement_terms', {});
  }, []);

  const handleCert = () => {
    const checkedItems = TERMS_LIST?.filter(
      item => item.id !== 3 && ADS.includes(item.id) && agreedList.includes(`${item.id}`),
    )
      .map(item => item.name)
      .join(',');

    handlePASSCertification();
    handleMixpanelEvent('click_button_agreement_terms', { type: checkedItems });
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        title="본인 인증 동의"
        toggleModal={handleCloseModal}
        btns={[
          {
            title: '인증하기',
            handleClick: handleCert,
            disabled: !REQUIRED_TERMS.every(id => agreedList.includes(`${id}`)),
          },
        ]}
      >
        <>
          <div className="row">
            <div className="certification-wrap">
              <div className="title-row">
                더 나은 서비스를 위해
                <br />
                1회 본인 인증이 필요해요!
              </div>
              <div className="img-row">
                <img src={topImg} alt="terms" />
              </div>
            </div>
          </div>
          <div className="row terms-check">
            <ul className="outer-list">
              <li className="list-item">
                <label className="checkbox-group green-box sm-box">
                  <input
                    type="checkbox"
                    onChange={handleCheckAll}
                    checked={agreedList.length === TERMS_LIST.length}
                  />
                  <span className="label">전체선택</span>
                  <span className="checkmark"></span>
                </label>
              </li>
              {TERMS_LIST.slice(0, 3).map(item => {
                return (
                  <li className="list-item" key={item.description}>
                    <label className="checkbox-group green-box sm-box">
                      <input
                        type="checkbox"
                        name={item.name}
                        value={item.id}
                        onChange={handleChangeCheckbox}
                        checked={agreedList.includes(`${item.id}`)}
                      />
                      <span className="label">{item.description}</span>
                      <span className="checkmark"></span>
                    </label>
                    {item.id < 3 && (
                      <Link
                        className="terms-link"
                        to={item.name === 'terms' ? AGREEMENT_TERMS_URL : PRIVACY_AGREEMENT_URL}
                        target="_blank"
                      >
                        자세히
                      </Link>
                    )}
                    {item.id === 3 && (
                      <ul className="inner-list">
                        {TERMS_LIST.slice(3).map(item => (
                          <li className="list-item" key={item.description}>
                            <label className="checkbox-group green-box sm-box">
                              <input
                                type="checkbox"
                                name={item.name}
                                value={item.id}
                                onChange={handleChangeCheckbox}
                                checked={agreedList.includes(`${item.id}`)}
                              />
                              <span className="label">{item.description}</span>
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      </Modal>
    </div>
  );
};
