import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { type WoDetailedProduct, WoProductGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  productIdx: string;
  referrerIdx: string | null;
  isAdmin?: boolean;
  options?: QueryOptionsType<WoDetailedProduct>;
}
export function useFetchProductDetail({ isAdmin, productIdx, referrerIdx }: Props) {
  if (referrerIdx) {
  }
  const getProductDetail = async () => {
    return await get(
      `/api/commerce/v1/products/${productIdx}?include_pending=${isAdmin}${
        referrerIdx ? `&referrer_influencer_idx=${referrerIdx}` : ''
      }`,
    )
      .then(res => res.data as WoProductGetRes)
      .catch(err => err);
  };

  return useQuery({
    queryKey: ['useFetchProductDetail', productIdx, referrerIdx],
    queryFn: getProductDetail,
  });
}
