import { Link } from 'react-router-dom';

import deleteIc from '@/assets/ic/x-sm-gr.svg';

interface KeywordListProps {
  type: 'recent' | 'popular';
  title: string;
  list: string[];
  handleSearchAction: (keyword: string) => void;
  handleDeleteSingleKeyword?: (keyword: string) => void;
}
export const KeywordList = ({
  type,
  title,
  list,
  handleSearchAction,
  handleDeleteSingleKeyword,
}: KeywordListProps) => {
  return (
    <ul className={`search-badge-group${type === 'popular' ? ' best' : ''}`}>
      {list.map((item: string, idx: number) => (
        <li
          className="badge-search"
          key={`${title}_${item}_${idx}`}
          onClick={() => {
            handleSearchAction(item);
          }}
        >
          {item}
          {type === 'recent' && handleDeleteSingleKeyword && (
            <Link
              className="btn-delete"
              to="#"
              onClick={e => {
                e.preventDefault();
                handleDeleteSingleKeyword(item);
              }}
            >
              <img src={deleteIc} alt="" />
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};
