import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { type WoProductsGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  type: string; // 'friendly' | 'special' | 'group_buying'
  value: {
    orderColumn: string; // 'sale_count' | 'discounted_price' | 'created_at';
    order?: string; // 'desc' | 'asc';
  };
  categoryId?: string;
  ingredientId?: string;
  brandId?: string;
  offset: number;
  limit: number;
  csShowcaseIdx?: number;
  referrerInfluencerIdx?: string;
  options?: QueryOptionsType<WoProductsGetRes>;
  referrer_tag_idx?: number;
}
export function useFetchProductList({
  categoryId,
  ingredientId,
  brandId,
  type,
  value,
  offset,
  limit,
  csShowcaseIdx,
  referrerInfluencerIdx,
  referrer_tag_idx,
  options,
}: Props) {
  const returnSearchParam = (key: string, value?: string) => (value ? `&${key}=${value}` : '');
  const optionalFilter = `${returnSearchParam('category_id', categoryId)}${returnSearchParam(
    'ingredient_id',
    ingredientId,
  )}${returnSearchParam('brand_id', brandId)}`;
  const props = `?collection_type_code=${type}&order_column=${value.orderColumn}&order=${
    value.order
  }&offset=${offset}&limit=${limit}${optionalFilter}&referrer_influencer_idx=${
    referrerInfluencerIdx ?? ''
  }${csShowcaseIdx ? `&cs_showcase_idx=${csShowcaseIdx}` : ''}&referrer_tag_idx${
    referrer_tag_idx ?? ''
  }`;

  const getProductList = async () => {
    return await get(`/api/commerce/v1/products${props}`).then(res => res.data);
  };

  return useQuery({
    queryKey: ['useFetchProductList', value, offset, limit, categoryId, ingredientId, brandId],
    queryFn: getProductList,
    ...options,
  });
}
