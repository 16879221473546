import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AlertModal } from '@components/common/molecules/modal/alert-modal';

import { useMutationAddCart } from '@/apis/commerce/cart/useMutationAddCart';
import { useMutationPreorder } from '@/apis/commerce/preorders/useMutationPreorder';
import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import productBoxIc from '@/assets/ic/product-box.svg';
import { PATH } from '@/constants/path';
import { WoProductOption } from '@/generated/api/type/data-contracts';
import useLoginInfo from '@/hooks/use-login-info';
import useMixpanel from '@/hooks/use-mixpanel';
import { useProduct } from '@/hooks/use-product';
import { getSessionStorage, removeSessionStorage, setSessionStorage } from '@/utils';
import { numberFormat } from '@/utils/formats';

interface OrderBtnBoxProps {
  options?: WoProductOption[];
  isSoldOut: boolean;
  toggleModal?: () => void; // 모바일인경우
  onSuccessAddingCart: (title: string, duration: number) => void;
  located: 'web_top' | 'web_mid' | 'mobile_bottom';
  handleAlert: (alert: {
    isOpen: boolean;
    header: string[];
    body: string[];
    btns: { title: string; handleClick: () => void }[];
  }) => void;
}

/**
 * 상품 상세에서 장바구니, 바로구매 버튼 박스
 */
export const OrderBtnBox = ({
  options,
  isSoldOut,
  toggleModal,
  onSuccessAddingCart,
  located,
  handleAlert,
}: OrderBtnBoxProps) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { id } = useParams();
  const { isLogin, userToken } = useLoginInfo();
  const { refetch: refetchUserDetail } = useFetchGetUserDetail({
    accessToken: userToken,
  });

  const referrerIdx = getSessionStorage('REFERRER');
  const { productContext, productDispatch } = useProduct();
  const [alert, setAlert] = useState({
    isOpen: false,
    title: [''],
    body: [''],
    btns: [{ title: '', handleClick: () => {} }],
  });
  const cartReqParam = productContext?.selectedOptions.map(option => ({
    product_idx: Number(id),
    product_option_idx: Number(option.product_option_idx),
    quantity: Number(option.optionCount),
    referrer_influencer_idx: Number(referrerIdx) ?? undefined,
    referrer_user_idx: undefined,
  }));

  const initParam = [
    {
      product_idx: Number(id),
      product_option_idx: 0,
      quantity: 0,
      referrer_influencer_idx: Number(referrerIdx) ?? undefined,
      referrer_user_idx: undefined,
    },
  ];
  const { mutate: mutateOrder } = useMutationPreorder();
  const { mutate: mutateAddToCart } = useMutationAddCart();
  const { handleMixpanelEvent } = useMixpanel();
  const handleAddingCart = () => {
    if (
      productContext?.selectedOptions === undefined ||
      (productContext?.selectedOptions && productContext?.selectedOptions.length < 1)
    )
      return;
    for (let i = 0; i < productContext?.selectedOptions.length; i++) {
      handleMixpanelEvent('click_add_cart', {
        from: located,
        option_name: productContext?.selectedOptions[i].option_name,
        option_id: productContext?.selectedOptions[i].product_option_idx,
        option_location: located,
      });
    }
    mutateAddToCart(cartReqParam ?? initParam, {
      onSuccess: () => {
        productDispatch?.optionEmpty();
        onSuccessAddingCart('장바구니에 담았어요', 1000);
        refetchUserDetail();
        toggleModal && toggleModal();
      },
      onError: err => console.log(err.message),
    });
  };

  const redirectToOrder = () => {
    const selectedOptions =
      productContext?.selectedOptions.map(option => ({
        product_option_idx: option?.product_option_idx,
        quantity: option?.optionCount,
      })) ?? [];

    if (isLogin) {
      handleMixpanelEvent('click_order', { from: 'direct' });
      mutateOrder(
        {
          referrer_influencer_idx: Number(referrerIdx ?? 0),
          preorder_items: selectedOptions,
        },
        {
          onSuccess: data =>
            navigate(`${PATH.ORDER}?id=${data?.preorder_idx}`, { state: { from: 'direct' } }),
          onError: err => console.log(err.message),
        },
      );
    } else {
      handleAlert({
        isOpen: true,
        header: [''],
        body: ['로그인이 필요한 서비스에요.', '지금 로그인 하시겠어요?'],
        btns: [
          {
            title: '로그인',
            handleClick: () => {
              setSessionStorage('prd_opts', selectedOptions);
              const beforeUrl = pathname + search;
              navigate(`${PATH.LOGIN}?before_url=${encodeURIComponent(beforeUrl)}`);
            },
          },
        ],
      });
    }
  };

  const prdOptsSessionStorage = useMemo(() => {
    return getSessionStorage('prd_opts');
  }, [getSessionStorage('prd_opts')]);

  useEffect(() => {
    const previouslySelectedOptions = prdOptsSessionStorage;
    const optionIdxes = options?.map(option => option?.product_option_idx);
    const optionExist =
      previouslySelectedOptions &&
      previouslySelectedOptions.length > 0 &&
      optionIdxes?.includes(previouslySelectedOptions[0]?.product_option_idx ?? 0);

    if (isLogin && previouslySelectedOptions?.length > 0) {
      optionExist
        ? mutateOrder(
            {
              referrer_influencer_idx: Number(referrerIdx ?? 0),
              preorder_items: previouslySelectedOptions,
            },
            {
              onSuccess: data => {
                navigate(`${PATH.ORDER}?id=${data?.preorder_idx}`);
                removeSessionStorage('prd_opts');
              },
              onError: err => console.log(err.message),
            },
          )
        : removeSessionStorage('prd_opts');
    }
  }, [isLogin, options, prdOptsSessionStorage]);

  return (
    <>
      {alert.isOpen && (
        <AlertModal
          isOpen={alert.isOpen}
          header={alert.title}
          body={alert.body}
          buttons={alert.btns}
          closeAlert={() => setAlert({ ...alert, isOpen: false })}
        />
      )}
      <div className="total-price">
        <div className="label">총 상품 금액</div>
        <div className="discount-percent">{numberFormat(productContext?.totalSelectedPrice)}</div>
        <div className="unit">원</div>
      </div>
      {isSoldOut ? (
        <button type="button" className="btn btn-lg btn-filled btn-block" disabled>
          품절되었어요
        </button>
      ) : (
        <div className="btn-inline-group">
          <button
            type="button"
            className="btn btn-md btn-outline btn-block"
            onClick={handleAddingCart}
          >
            <img src={productBoxIc} alt="장바구니" />
            장바구니
          </button>
          <button
            type="button"
            className="btn btn-md btn-filled btn-block"
            onClick={redirectToOrder}
            disabled={
              productContext?.selectedOptions && productContext?.selectedOptions?.length < 1
            }
          >
            바로 구매
          </button>
        </div>
      )}
    </>
  );
};
